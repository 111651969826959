<template>
  <div>
    <b-table
      responsive
      show-empty
      striped
      hover
      small
      empty-text="No matching records found"
      style="text-align: center; font-size: small"
      :items="branchRates"
      :fields="fields"
      :tbody-tr-class="getRowClass"
    >
      <template #thead-top>
        <!-- First Row -->
        <b-tr>
          <b-th
            rowspan="2"
            class="text-center"
          >
            사용자 ID
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            사용자 이름
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            닉네임
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            상위 파트너
          </b-th>
          <b-th
            colspan="2"
            variant="secondary"
            class="text-center"
          >
            루징
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            스포츠
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            카지노
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            슬롯
          </b-th>
        </b-tr>
        <!-- Second Row -->
        <b-tr>
          <b-th class="text-center">
            비율
          </b-th>
          <b-th class="text-center">
            상위 비율
          </b-th>
          <b-th class="text-center">
            비율
          </b-th>
          <b-th class="text-center">
            상위 비율
          </b-th><b-th class="text-center">
            비율
          </b-th>
          <b-th class="text-center">
            상위 비율
          </b-th><b-th class="text-center">
            비율
          </b-th>
          <b-th class="text-center">
            상위 비율
          </b-th>
        </b-tr>
      </template>
      <template
        v-for="(field, index) in rateFields"
        v-slot:[`cell(${field})`]="data"
      >
        <span
          :key="index"
          :style="getStyle(data.item[field])"
        >
          {{ data.item[field] }}
        </span>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BTr, BTh,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_BRANCH_RATE } from '@/store/branch/action'

const branchStore = createNamespacedHelpers('branchStore')

function fncCalculateRate(data, baseData) {
  const map = {}

  data.forEach(item => {
    map[item.nickname] = { ...item, level: 0 }
  })

  data.forEach(node => {
    const getUpperRate = attribute => {
      let upperRate = 0
      let upperRateMsg = ''

      if (map[node.recommendNickname]) {
        upperRate = parseFloat((map[node.recommendNickname][attribute] - map[node.nickname][attribute]).toFixed(3))
        upperRateMsg = `${map[node.recommendNickname][attribute]} - ${map[node.nickname][attribute]}`
      } else {
        upperRate = '-'
        upperRateMsg = '-'
      }

      return {
        upperRate,
        upperRateMsg,
      }
    }

    const { upperRate, upperRateMsg } = getUpperRate('rate')
    const { upperRate: upperRateSportRolling, upperRateMsg: upperRateSportRollingMsg } = getUpperRate('rateSportRolling')
    const { upperRate: upperRateCasinoRolling, upperRateMsg: upperRateCasinoRollingMsg } = getUpperRate('rateCasinoRolling')
    const { upperRate: upperRateSlotRolling, upperRateMsg: upperRateSlotRollingMsg } = getUpperRate('rateSlotRolling')

    map[node.nickname].level = map[node.recommendNickname] ? map[node.recommendNickname].level + 1 : 0
    map[node.nickname].upperRate = upperRate
    map[node.nickname].upperRateMsg = upperRateMsg
    map[node.nickname].upperRateSportRolling = upperRateSportRolling
    map[node.nickname].upperRateSportRollingMsg = upperRateSportRollingMsg
    map[node.nickname].upperRateCasinoRolling = upperRateCasinoRolling
    map[node.nickname].upperRateCasinoRollingMsg = upperRateCasinoRollingMsg
    map[node.nickname].upperRateSlotRolling = upperRateSlotRolling
    map[node.nickname].upperRateSlotRollingMsg = upperRateSlotRollingMsg
  })
  // 결과 배열 생성
  // return Object.values(map)

  // const parentNode = map[baseData.recommendNickname] || null
  // const childNodes = data.filter(item => item.recommendNickname === baseData.nickname)
  // const baseNode = map[baseData.nickname]
  // const resultNode = [
  //   ...(parentNode ? [parentNode] : []), // 부모 노드 추가 (존재하는 경우)
  //   baseNode, // 본인 노드 추가
  //   ...childNodes, // 자식 노드들 추가
  // ]

  // 조상 노드만 출력
  const resultNode = []
  function fncfindParent(node) {
    const parentNode = map[node.recommendNickname]
    if (!parentNode) return
    resultNode.unshift(parentNode)
    fncfindParent(parentNode)
  }
  fncfindParent(baseData)
  // upperNodes.push(Object.values(map).find(item => item.userid === baseData.userid))
  resultNode.push(map[baseData.nickname])

  return resultNode
}

export default {
  name: 'BranchRate',
  components: {
    BTable, BTr, BTh,
  },
  props: {
    enrollUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),

      fields: [
        { key: 'userid', label: '사용자 ID', thClass: 'd-none' },
        { key: 'username', label: '사용자 이름', thClass: 'd-none' },
        { key: 'nickname', label: '닉네임', thClass: 'd-none' },
        { key: 'recommendNickname', label: '상위 파트너', thClass: 'd-none' },
        { key: 'rate', label: '비율', thClass: 'd-none' },
        { key: 'upperRate', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSportRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSportRolling', label: '상위', thClass: 'd-none' },
        { key: 'rateCasinoRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateCasinoRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSlotRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSlotRolling', label: '상위 비율', thClass: 'd-none' },
      ],
      rateFields: [
        'rate',
        'upperRate',
        'rateSportRolling',
        'upperRateSportRolling',
        'rateCasinoRolling',
        'upperRateCasinoRolling',
        'rateSlotRolling',
        'upperRateSlotRolling',
      ],
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranch: 'fetchBranch',
      fetchBranchRate: 'fetchBranchRate',
    }),
    branchRates() {
      let branchRates = []

      branchRates = this.fetchBranchRate.filter(item => item.userid !== this.enrollUser.userid)

      if (this.enrollUser.userid) {
        branchRates.push({
          site: this.enrollUser.site,
          userid: this.enrollUser.userid,
          username: this.enrollUser.username,
          nickname: this.enrollUser.nickname,
          recommendNickname: this.enrollUser.recommendNickname,
          role: this.enrollUser.role,
          rate: this.enrollUser.rate,
          rateSportRolling: this.enrollUser.rateSportRolling,
          rateCasinoRolling: this.enrollUser.rateCasinoRolling,
          rateSlotRolling: this.enrollUser.rateSlotRolling,
        })
      }

      const calculateBranchRateResult = fncCalculateRate(branchRates, this.enrollUser)
      this.validateRate(calculateBranchRateResult)
      // return calculateBranchRateResult
      return calculateBranchRateResult
    },
    highlightedRow() {
      return this.enrollUser.userid // 현재 하이라이트할 사용자 ID 반환
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchRate: FETCH_BRANCH_RATE,
    }),
    async fetchData() {
      await this.$fetchBranchRate({
        site: localStorage.getItem('authSiteSelected'),
        userid: this.userData.userid,
      })
    },
    validateRate(data) {
      const enrollUser = data.find(item => item.userid === this.enrollUser.userid)
      this.$emit('validate-rate', {
        isUpperRate: enrollUser.upperRate >= 0,
        isUpperRateSportRolling: enrollUser.upperRateSportRolling >= 0,
        isUpperRateCasinoRolling: enrollUser.upperRateCasinoRolling >= 0,
        isUpperRateSlotRolling: enrollUser.upperRateSlotRolling >= 0,
      })
    },
    formatAuthSites(authSites) {
      return Array.isArray(authSites) && authSites.length > 0 ? authSites.join(', ') : ''
    },
    getRowClass(item) {
      return item.userid === this.highlightedRow ? 'highlight-row' : ''
    },
    getStyle(value) {
      return {
        color: value < 0 ? 'red' : 'inherit', // 음수일 경우 빨간색, 그렇지 않으면 기본 색상
        fontWeight: value < 0 ? 'bold' : 'normal',
      }
    },
  },
}

</script>

<style>
.highlight-row {
  background-color: #464251 !important; /* 원하는 하이라이트 색상으로 변경 */
}
.b-table > tbody > tr.highlight-row:hover {
      background-color: #464251 !important;
}
</style>
