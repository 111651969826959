<template>
  <b-modal
    :id="componentName"
    no-close-on-backdrop
    hide-footer
    centered
    title="회원 검색"
    @shown="btnModal('show')"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        회원 검색
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click.prevent="btnModal('close')"
        />
      </div>
    </template>
    <b-card
      no-body
    >

      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="권한"
            label-for="search-role"
          >
            <b-form-select
              id="search-role"
              v-model="selectedRole"
              size="sm"
              :options="optionsRole"
              class="font-small-3"
              style="color:rgb(229,166,48)"
            >
              <template #first>
                <option
                  :value="null"
                  style="color: white;"
                >
                  == 전체 ==
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="상태"
            label-for="search-rank"
          >
            <b-form-select
              id="search-rank"
              v-model="selectedStatus"
              size="sm"
              :options="optionsStatus"
              class="font-small-3"
              style="color:rgb(229,166,48)"
            >
              <template #first>
                <option
                  :value="null"
                  style="color: white;"
                >
                  == 전체 ==
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="false"
          cols="12"
          md="4"
        >
          <b-form-group
            label="총판"
            label-for="search-status"
          >
            <b-form-select
              id="search-status"
              v-model="selectedQualify"
              size="sm"
              :options="optionsQualify"
              class="font-small-3"
              style="color:rgb(229,166,48)"
            >
              <template #first>
                <option
                  :value="null"
                  style="color: white;"
                >
                  == 전체 ==
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group
              class="font-small-3"
              label="회원검색"
              label-for="searchUsers"
            >
              <b-input-group size="sm">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchUsers"
                  v-model="searchFilter"
                  placeholder="회원 검색..."
                  debounce="500"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="btnReset"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <div class="font-small-3 text-success mb-1">
            ※ 총판 계정 생성 시, 회원검색
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            size="sm"
            class="font-small-3"
            label="Show"
            label-for="message-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-sm="left"
          >
            <b-form-select
              id="message-perPage"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              size="sm"
              :clearable="false"
              class="half-width"
            />
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col cols="12">
          <b-table
            ref="refTable"
            responsive
            show-empty
            striped
            hover
            small
            empty-text="No matching records found"
            class="no-select"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="fields"
          >
            <!-- Column: No -->
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(userInfo)="data">
              <div
                class="font-weight-bold d-block text-nowrap"
                style="text-align: center !important"
              >
                {{ data.item.userid }}
              </div>
              <div
                class="text-muted"
                style="text-align: center !important"
              >
                {{ data.item.nickname }}
              </div>
            </template>
            <!-- Column: Role -->
            <template #cell(role)="data">
              <b-media class="align-content-center">
                <template>
                  <b-avatar
                    size="32"
                    :src="data.item.role"
                    :variant="`${resolveUserRoleVariant(data.item.role)}`"
                  />
                </template>
              </b-media>
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                <!-- {{ data.item.status }} -->
                {{ convertUserStatus(data.item.status) }}
              </b-badge>
            </template>
            <template #cell(isRoleUp)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-center"
                @click="selectedRow(data.item)"
              >
                총판전환
              </b-button>
            </template>

            <template #empty>
              <div class="text-center my-4">
                <!-- <p>No matching records found.</p> -->
                <p>검색된 사용자가 없습니다.</p>
                <b-button
                  v-if="searchFilter.length >= 3 && fetchUser"
                  variant="primary"
                  @click="enrollUser"
                >
                  "{{ searchFilter }}" 로 새로운 총판 만들기
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BTable, BPagination, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormGroup, BFormInput, BForm, BFormSelect, BModal, BMedia, BAvatar, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_USER, FETCH_BRANCH_SEARCH_USER } from '@/store/branch/action'

const branchStore = createNamespacedHelpers('branchStore')
export default {
  name: 'BranchSearchUser',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BModal,
    BMedia,
    BAvatar,
    BBadge,

    // formatDate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),

      selectedRole: null,
      selectedStatus: null,
      selectedQualify: null,

      isSelected: false,

      optionsStatus: [
        { value: 'active', text: '정상' },
        { value: 'sleep', text: '휴면' },
        { value: 'block', text: '차단' },
      ],

      optionsQualify: [
        { value: 2, text: '총판수정' },
        { value: 1, text: '총판이관' },
        { value: -99, text: '불가' },
      ],

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      fields: [
        { key: 'no', label: 'No.' },
        { key: 'userInfo', label: '아이디/닉네임' },
        { key: 'username', label: '닉네임/이름' },
        { key: 'email', label: '이메일' },
        { key: 'mobile', label: '전화번호' },
        { key: 'role', label: '권한' },
        { key: 'status', label: '상태' },
        { key: 'isRoleUp', label: '선택' },
      ],
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchUser: 'fetchUser',
      fetchBranchSearchUser: 'fetchBranchSearchUser',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.fetchBranchSearchUser

      filteredData = filteredData.filter(item => item.isRoleUp)

      if (this.selectedRole) {
        filteredData = filteredData.filter(item => item.role === this.selectedRole)
      }

      if (this.selectedStatus) {
        filteredData = filteredData.filter(item => item.status === this.selectedStatus)
      }

      if (this.selectedQualify) {
        filteredData = filteredData.filter(item => item.isRoleUp.code === this.selectedQualify)
      }

      // 객체 모든 속성을 검색
      // if (this.searchFilter) {
      //   filteredData = filteredData.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      // }

      // 계정만 조회
      // if (this.searchFilter) {
      //   filteredData = filteredData.filter(item => ['userid'].some(key => item[key] && item[key].toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      // }

      // 특정 속성을 검색
      const searchKeys = ['userid', 'nickname', 'username', 'email', 'mobile']
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => searchKeys.some(key => item[key] && item[key].toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }

      // 정렬
      filteredData.sort((a, b) => {
        const order = { godfather: 0, admin: 1, branch: 2, user: 3 }

        // return order[a.role] - order[b.role] // 오름차순
        return order[b.role] - order[a.role] // 내림차순
      })
      return filteredData
    },

    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end)

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
    optionsRole() {
      const roleMapping = {
        godfather: 'godfather',
        branch: '총판',
        admin: '어드민',
        user: '유저',
      }
      return [...new Set(this.filteredItems
        .map(user => user.role))]
        .map(role => ({ value: role, text: roleMapping[role] || role }))
    },
  },
  watch: {
    searchFilter: {
      handler(newVal) {
        if (newVal.length >= 3) {
          clearTimeout(this.searchTimeout) // 기존 타이머를 제거하여 디바운스를 구현
          this.searchTimeout = setTimeout(() => {
            this.checkUserid()
          }, 1000) // 1초 (1000ms) 뒤에 수행
        }
      },
      immediate: true,
    },
  },
  // created() {
  //   console.log(this.componentName, 'created()')
  // },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    document.addEventListener('keydown', this.handleEscKey)
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 이벤트 리스너 제거
    document.removeEventListener('keydown', this.handleEscKey)
  },
  methods: {
    ...branchStore.mapActions({
      $fetchUser: FETCH_USER,
      $fetchBranchSearchUser: FETCH_BRANCH_SEARCH_USER,
    }),
    async init() {
      this.isSelected = false
      await this.$fetchBranchSearchUser({
        site: localStorage.getItem('authSiteSelected'),
        userid: this.userData.userid,
      })
    },
    async checkUserid() {
      await this.$fetchUser({
        userid: this.searchFilter,
      })
    },
    enrollUser() {
      this.selectedRow({
        userid: this.searchFilter,
        type: 0,
      })
    },
    selectedRow(data) {
      this.isSelected = true // 객체가 선택되었음을 표시
      this.$emit('selected-row', {
        ...data,
        type: Object.prototype.hasOwnProperty.call(data, 'type') ? data.type : 1,
      })
      this.btnModal('close')
    },
    btnReset() {
      this.searchFilter = ''
      this.selectedRole = null
      this.selectedStatus = null
      this.selectedQualify = null
    },
    // Modal
    btnModal(target) {
      if (target === 'show') {
        // console.log(this.componentName, 'btnModal()', target)
        this.init()
      }
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.btnReset()
        this.$bvModal.hide(this.componentName)
        if (!this.isSelected) {
          this.$router.go(-1)
        }
      }
    },
    handleEscKey(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        // Esc 키가 눌렸을 때 모달 닫기
        this.btnModal('close')
      }
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },

    resolveUserStatusVariant(status) {
      if (status === 'apply') return 'info'
      if (status === 'additional') return 'info'
      if (status === 'await') return 'info'
      if (status === 'active') return 'success'
      if (status === 'sleep') return 'primary'
      if (status === 'block') return 'primary'
    },

    convertUserStatus(status) {
      if (status === 'apply') return '1차가입'
      if (status === 'additional') return '2차가입'
      if (status === 'await') return '최종대기'
      if (status === 'active') return '정상'
      if (status === 'sleep') return '휴먼'
      if (status === 'block') return '차단'
    },
  },
}
</script>

<style>
  .modal-dialog {
    max-width: 1100px;
  }
</style>
